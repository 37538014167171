<template>
  <div class="ModalConfirm">
    <div class="modal-header">
      <h5 class="modal-title">
        <div v-html="title" :class="'text-' + type" />
      </h5>

      <button type="button" class="btn-close" @click="modal.close()" />
    </div>

    <div class="modal-body">
      <div v-html="text" />
    </div>

    <div class="modal-footer">
      <button type="button" :class="'btn btn-outline-' + type" v-focus @click="modal.close()">
        Cancel
      </button>

      <button type="button" :class="'btn btn-' + type" @click="modal.close(true)">
        Confirm
      </button>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: 'Confirmation'
      },
      text: {
        type: String,
        default: 'Are you sure?'
      },
      type: {
        type: String,
        default: 'primary'
      },
      modal: {
        type: Object,
        default: () => ({})
      }
    }
  }
</script>
