import api from '@/shared/api/token'
import { pick } from 'lodash'

const ENDPOINT = '/aum'

class AumService {
  fields = [
    'id',
    'amount',
    'date'
  ]

  fetch() {
    return api.get(ENDPOINT).then((response) => {
      return response.data.data
    })
  }

  get(id) {
    return api.get(ENDPOINT + '/' + id).then((response) => {
      return response.data.data
    })
  }

  save(data) {
    if (data.id) {
      return this.update(data.id, data)
    } else {
      return this.create(data)
    }
  }

  create(data) {
    data = pick(data, this.fields)

    return api.post(ENDPOINT, data).then((response) => {
      return response.data
    })
  }

  update(id, data) {
    data = pick(data, this.fields)

    return api.put(ENDPOINT + '/' + id, data).then((response) => {
      return response.data
    })
  }

  delete(id) {
    return api.delete(ENDPOINT + '/' + id).then((response) => {
      return response.data
    })
  }
}

export default new AumService()
