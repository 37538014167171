<template>
  <div class="AppEmptyList">
    <div class="AppEmptyList__icon">
      <IconEmpty />
    </div>

    <div class="AppEmptyList__text">
      Nothing here yet…
    </div>
  </div>
</template>

<script>
  import IconEmpty from '@/components/IconEmpty'

  export default {
    components: {
      IconEmpty
    }
  }
</script>

<style lang="sass" scoped>
@import '@/sass/styles.vars.all.sass'

.AppEmptyList
  display: flex
  justify-content: center
  align-items: center
  height: 300px
  flex-direction: column

.AppEmptyList__icon
  opacity: .1

  *
    fill: $primary

  svg
    height: 150px
</style>
